import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Pile } from "./pile.js";

const GameViewBase = styled.div`
  margin-top: 2em;
`;

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

export const GameView = ({ state }) => {
  return (
    <GameViewBase>
      <CardRow>
        <Pile pileName="stack1" cards={state.stack1} spacing={0} />
        <Pile pileName="stack2" cards={state.stack2} spacing={0} />
        <Pile pileName="stack3" cards={state.stack3} spacing={0} />
        <Pile pileName="stack4" cards={state.stack4} spacing={0} />
        <CardRowGap />
        <Pile pileName="draw" cards={state.draw} spacing={0} />
        <Pile pileName="discard" cards={state.discard} spacing={0} />
      </CardRow>
      <CardRow>
        <Pile pileName="pile1" cards={state.pile1} />
        <Pile pileName="pile2" cards={state.pile2} />
        <Pile pileName="pile3" cards={state.pile3} />
        <Pile pileName="pile4" cards={state.pile4} />
        <Pile pileName="pile5" cards={state.pile5} />
        <Pile pileName="pile6" cards={state.pile6} />
        <Pile pileName="pile7" cards={state.pile7} />
      </CardRow>
    </GameViewBase>
  );
};

GameView.propTypes = {
  state: PropTypes.object.isRequired,
};