/* src/client/components/edit-profile.js */
"use strict";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormLabel,
  FormInput,
  FormButton,
} from "./shared.js";
import PropTypes from "prop-types"; // Import PropTypes
import { Notification } from "./Notification.js"; // Adjust path if necessary



export const EditProfile = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    city: "",
    primary_email: "",
    error: "",
  });
  
  const [notificationVisible, setNotificationVisible] = useState(false);

  useEffect(() => {
    // Fetch current user data to populate the form
    const fetchUserData = async () => {
      const res = await fetch("/v1/session", { credentials: "include" });
      if (res.ok) {
        const user = await res.json();
        setState((prevState) => ({
          ...prevState,
          first_name: user.first_name || "",
          last_name: user.last_name || "",
          city: user.city || "",
          primary_email: user.primary_email || "",
        }));
      } else {
        navigate("/login");
      }
    };
    fetchUserData();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, city, primary_email } = state;
    const res = await fetch("/v1/user", {
      method: "PUT",
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ first_name, last_name, city, primary_email }),
    });
  
    if (res.ok) {
      const updatedUser = await res.json();
      // Update localStorage
      localStorage.setItem("user", JSON.stringify(updatedUser));
  
      // Show notification
      setNotificationVisible(true);
      // Hide notification after 3 seconds
      setTimeout(() => setNotificationVisible(false), 3000);
  
      // Navigate back to the profile page
      navigate(`/profile/${updatedUser.username}`);
    } else {
      const errorData = await res.json();
      setState((prevState) => ({
        ...prevState,
        error: errorData.error || "Failed to update profile",
      }));
    }
  };

  EditProfile.propTypes = {
    updateUser: PropTypes.func.isRequired,
  };

  return (
    <div style={{ gridArea: "main" }}>
      <ErrorMessage msg={state.error} />
      <FormBase onSubmit={handleSubmit}>
        <FormLabel htmlFor="first_name">First Name:</FormLabel>
        <FormInput
          id="first_name"
          name="first_name"
          type="text"
          value={state.first_name}
          onChange={handleChange}
        />
  
        <FormLabel htmlFor="last_name">Last Name:</FormLabel>
        <FormInput
          id="last_name"
          name="last_name"
          type="text"
          value={state.last_name}
          onChange={handleChange}
        />
  
        <FormLabel htmlFor="city">City:</FormLabel>
        <FormInput
          id="city"
          name="city"
          type="text"
          value={state.city}
          onChange={handleChange}
        />
  
        <FormLabel htmlFor="primary_email">Email Address:</FormLabel>
        <FormInput
          id="primary_email"
          name="primary_email"
          type="email"
          value={state.primary_email}
          onChange={handleChange}
        />
  
        <div />
        <FormButton type="submit">Save Changes</FormButton>
      </FormBase>
  
      {/* Render Notification */}
      <Notification visible={notificationVisible} />
    </div>
  );
};
