import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ErrorMessage } from "./shared.js";
import { GameView } from "./GameView.js";


const ResultsBase = styled.div`
  grid-area: main;
  padding-top: 2em;
`;

const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 1em 0;
`;

const StyledButton = styled(Link)`
  display: inline-block;
  padding: 0.75em 1.5em;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #007bff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

const MovesTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;

  th,
  td {
    border: 1px solid #ddd;
    padding: 0.5em;
    text-align: center;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

const MovesList = ({ moves, onMoveClick }) => {
  return (
    <MovesTable>
      <thead>
        <tr>
          <th>#</th>
          <th>Timestamp</th>
          <th>Source</th>
          <th>Destination</th>
          <th>Cards Moved</th>
        </tr>
      </thead>
      <tbody>
        {moves.map((move, index) => (
          <tr key={index} onClick={() => onMoveClick(index)}>
            <td>{index + 1}</td>
            <td>{new Date(move.date).toLocaleString()}</td>
            <td>{move.src}</td>
            <td>{move.dst}</td>
            <td>
              {move.cards.map((card) => `${card.value} of ${card.suit}`).join(", ")}
            </td>
          </tr>
        ))}
      </tbody>
    </MovesTable>
  );
};

MovesList.propTypes = {
  moves: PropTypes.array.isRequired,
  onMoveClick: PropTypes.func.isRequired,
};
MovesList.propTypes = {
  moves: PropTypes.array.isRequired,
};

export const Results = () => {
  const { id } = useParams();
  const [moves, setMoves] = useState([]);
  const [error, setError] = useState("");
  const [selectedState, setSelectedState] = useState(null); // Holds the game state after the selected move

  useEffect(() => {
    const fetchMoves = async () => {
      try {
        const response = await fetch(`/v1/game/${id}/moves`, {
          credentials: "include",
        });
        if (!response.ok) {
          const data = await response.json();
          setError(data.error || "Failed to fetch moves");
          return;
        }
        const data = await response.json();
        setMoves(data.moves);
      } catch (err) {
        console.error("Error fetching moves:", err);
        setError("Failed to fetch moves");
      }
    };
    fetchMoves();
  }, [id]);

  const handleMoveClick = async (moveIndex) => {
    try {
      const response = await fetch(`/v1/game/${id}/state/${moveIndex}`, {
        credentials: "include",
      });
      if (!response.ok) {
        const data = await response.json();
        setError(data.error || "Failed to fetch game state");
        return;
      }
      const gameState = await response.json();
      setSelectedState(gameState);
    } catch (err) {
      console.error("Error fetching game state:", err);
      setError("Failed to fetch game state");
    }
  };

  return (
    <ResultsBase>
      {/* "Back to Game" Button */}
      <ButtonContainer>
        <StyledButton to={`/game/${id}`}>Back to Game</StyledButton>
      </ButtonContainer>

      <h2>Game Results</h2>
      {error && <ErrorMessage msg={error} />}
      {!error && moves.length === 0 && <p>No moves recorded for this game.</p>}
      {!error && moves.length > 0 && (
        <MovesList moves={moves} onMoveClick={handleMoveClick} />
      )}

      {/* Display the game state after the selected move */}
      {selectedState && (
        <div>
          <h3>Game State After Move</h3>
          <GameView state={selectedState} />
        </div>
      )}
    </ResultsBase>
  );
};