"use strict";
//src/client/components/login.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormLabel,
  FormInput,
  FormButton,
} from "./shared.js";

export const Login = (props) => {
  let navigate = useNavigate();
  let [username, setUser] = useState("");
  let [password, setPass] = useState("");
  let [error, setError] = useState("");

  const onSubmit = async (ev) => {
    ev.preventDefault();
    const res = await fetch("/v1/session", {
      body: JSON.stringify({ username, password }),
      method: "POST",
      credentials: "include",
      headers: { "content-type": "application/json" },
    });
  
    const data = await res.json();
    if (res.ok) {
      props.logIn(data.username);
      navigate(`/profile/${data.username}`);
    } else {
      setError(data.error || "An error occurred");
    }
  };

  useEffect(() => {
    document.getElementById("username").focus();
  }, []);

  return (
    <div style={{ gridArea: "main", padding: "1em" }}>
      <ErrorMessage msg={error} />
      <FormBase
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          width: "100%",
        }}
      >
        {/* Grid Container */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gridGap: "0.5em",
            alignItems: "center",
          }}
        >
          <FormLabel htmlFor="username">Username:</FormLabel>
          <FormInput
            id="username"
            name="username"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(ev) => setUser(ev.target.value.toLowerCase())}
            style={{ width: "100%" }}
          />

          <FormLabel htmlFor="password">Password:</FormLabel>
          <FormInput
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(ev) => setPass(ev.target.value)}
            style={{ width: "100%" }}
          />

          {/* Empty div to align buttons with inputs */}
          <div></div>
          {/* Buttons Container */}
          <div style={{ width: "100%" }}>
            <FormButton
              id="submitBtn"
              onClick={onSubmit}
              style={{
                width: "100%",
                marginBottom: "0.5em",
              }}
            >
              Login
            </FormButton>

            {/* GitHub Login Button */}
            <a
              href="/v1/auth/github"
              style={{
                textDecoration: "none",
                width: "100%",
                display: "block",
              }}
            >
              <FormButton
                type="button"
                style={{
                  backgroundColor: "#333",
                  color: "#fff",
                  width: "100%",
                }}
              >
                Login with GitHub
              </FormButton>
            </a>
          </div>
        </div>
      </FormBase>
    </div>
  );
};

Login.propTypes = {
  logIn: PropTypes.func.isRequired,
};