// //src/client/components/game.jsimport React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import styled from "styled-components";
// import { Pile } from "./pile.js";
// import game from "../../server/models/game.cjs";

// const CardRow = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: center;
//   align-items: flex-start;
//   margin-bottom: 2em;
// `;

// const CardRowGap = styled.div`
//   flex-grow: 2;
// `;

// const GameBase = styled.div`
//   grid-row: 2;
//   grid-column: sb / main;
// `;

// export const Game = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [state, setState] = useState({
//     pile1: [], pile2: [], pile3: [], pile4: [], pile5: [], pile6: [], pile7: [],
//     stack1: [], stack2: [], stack3: [], stack4: [],
//     draw: [], discard: [],
//     active: true
//   });

//   const [selectedCards, setSelectedCards] = useState([]);
//   const [selectedSource, setSelectedSource] = useState("");
//   const [moveInProgress, setMoveInProgress] = useState(false);
//   const [highlightedCards, setHighlightedCards] = useState(null);

//   useEffect(() => {
//     const getGameState = async () => {
//       try {
//         const response = await fetch(`/v1/game/${id}`, {
//           credentials: 'include',
//         });
//         if (response.status === 401) {
//           navigate('/login');
//           return;
//         }
//         const data = await response.json();
  
//         console.log("Game state received:", data); // Add for debugging
  
//         // Set state with a default for `active`
//         setState((prevState) => ({
//           ...prevState,
//           ...data,
//           active: true
//         }));
//       } catch (err) {
//         console.error("Failed to load game:", err);
//       }
//     };
//     getGameState();
//   }, [id, navigate]);
  

//   // Handle Escape key
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === "Escape") {
//         resetMove();
//       }
//     };
//     window.addEventListener("keydown", handleKeyDown);
//     return () => window.removeEventListener("keydown", handleKeyDown);
//   }, []);

//   const resetMove = () => {
//     setSelectedCards([]);
//     setSelectedSource("");
//     setMoveInProgress(false);
//     setHighlightedCards(null);
//   };

//   // Update sendMoveRequest to log server responses
//   const sendMoveRequest = async (moveJSON) => {
//     try {
//       const response = await fetch(`/v1/game/${id}`, {
//         method: "PUT",
//         credentials: 'include',
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify(moveJSON)
//       });
  
//       const data = await response.json();
//       console.log("Server response:", data); // Log server response
  
//       if (!response.ok) {
//         console.error("Move failed:", data.error);
//         // Display the error to the user
//         return;
//       }
  
//       setState(data);
//     } catch (err) {
//       console.error("Move error:", err);
//       console.error("Move error:", err);
//     } finally {
//       resetMove();
//     }
//   };

//   const onClick = (card, pileName, cardIndex) => {
//     console.log("Click detected:", { card, pileName, cardIndex });
  
//     if (pileName === "draw") {
//       sendMoveRequest({ cards: [], src: "draw", dst: "discard" });
//       return;
//     }
  
//     if (!moveInProgress) {
//       if (!card?.up) {
//         resetMove();
//         return;
//       }
  
//       const pileCards = state[pileName];
//       const selectedCards = pileCards.slice(cardIndex);
  
//       if (selectedCards.some((c) => !c.up)) {
//         resetMove();
//         return;
//       }
  
//       console.log("Selected cards:", selectedCards);
//       setSelectedCards(selectedCards);
//       setSelectedSource(pileName);
//       setMoveInProgress(true);
//       setHighlightedCards({
//         pileName,
//         indices: pileCards.map((_, idx) => idx).slice(cardIndex),
//       });
//       return;
//     }
  
//     if (pileName === selectedSource) {
//       resetMove();
//       return;
//     }
  
//     const moveJSON = {
//       cards: selectedCards.map((c) => ({
//         suit: c.suit,
//         value: c.value,
//       })),
//       src: selectedSource,
//       dst: pileName,
//     };
  
//     console.log("Sending move request:", moveJSON);
//     sendMoveRequest(moveJSON);
//   };
  
  
//   return (
//     <GameBase onClick={e => {
//       if (e.target === e.currentTarget) resetMove();
//     }}>
//       <CardRow>
//         <Pile pileName="stack1" cards={state.stack1} spacing={0} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="stack2" cards={state.stack2} spacing={0} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="stack3" cards={state.stack3} spacing={0} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="stack4" cards={state.stack4} spacing={0} onClick={onClick} highlightedCards={highlightedCards}/>
//         <CardRowGap />
//         <Pile pileName="draw" cards={state.draw} spacing={0} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="discard" cards={state.discard} spacing={0} onClick={onClick} highlightedCards={highlightedCards}/>
//       </CardRow>
//       <CardRow>
//         <Pile pileName="pile1" cards={state.pile1} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="pile2" cards={state.pile2} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="pile3" cards={state.pile3} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="pile4" cards={state.pile4} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="pile5" cards={state.pile5} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="pile6" cards={state.pile6} onClick={onClick} highlightedCards={highlightedCards}/>
//         <Pile pileName="pile7" cards={state.pile7} onClick={onClick} highlightedCards={highlightedCards}/>
//       </CardRow>
//     </GameBase>
//   );
// };
// //src/client/components/game.jsimport React, { useState, useEffect } from "react";

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
`;

const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 1em 0;
`;

const StyledButton = styled(Link)`
  display: inline-block;
  padding: 0.75em 1.5em;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #007bff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

const ExtraSpace = styled.div`
  height: 10em; /* Adjust height to increase or decrease white space */
`;

export const Game = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    pile1: [], pile2: [], pile3: [], pile4: [], pile5: [], pile6: [], pile7: [],
    stack1: [], stack2: [], stack3: [], stack4: [],
    draw: [], discard: [],
    active: true
  });

  useEffect(() => {
    const getGameState = async () => {
      try {
        const response = await fetch(`/v1/game/${id}`, {
          credentials: 'include',
        });
        if (response.status === 401) {
          navigate('/login');
          return;
        }
        const data = await response.json();

        console.log("Game state received:", data); // Add for debugging

        // Set state with a default for active
        setState((prevState) => ({
          ...prevState,
          ...data,
          active: true
        }));
      } catch (err) {
        console.error("Failed to load game:", err);
      }
    };
    getGameState();
  }, [id, navigate]);

  const sendMoveRequest = async (moveJSON) => {
    try {
      const response = await fetch(`/v1/game/${id}`, {
        method: "PUT",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(moveJSON)
      });

      const data = await response.json();
      console.log("Server response:", data); // Log server response

      if (!response.ok) {
        console.error("Move failed:", data.error);
        // Display the error to the user
        return;
      }

      setState(data);
    } catch (err) {
      console.error("Move error:", err);
    }
  };

  const onDragStart = (e, card, cardIndex) => {
    console.log("Drag start:", card);
    const pileName = findCardPile(card);
  
    if (!pileName) {
      console.error("Pile not found for the dragged card.");
      return;
    }
  
    // For foundation moves, only one card is moved
    let movingCards;
    if (pileName.startsWith("pile") || pileName === "discard") {
      // Get the top card if moving to foundation
      const pile = state[pileName];
      const topCardIndex = pile.length - 1;
  
      if (cardIndex !== topCardIndex) {
        // Prevent dragging multiple cards to foundation
        console.error("Can only move the top card to foundation");
        e.preventDefault();
        return;
      }
  
      movingCards = [card];
    } else {
      // For other piles, allow moving multiple cards
      const pile = state[pileName];
      movingCards = pile.slice(cardIndex);
    }
  
    // Ensure all moving cards are face up
    if (movingCards.some((c) => !c.up)) {
      console.error("Cannot drag face-down cards.");
      e.preventDefault();
      return;
    }
  
    e.dataTransfer.setData("application/json", JSON.stringify(movingCards));
  };

  const onDrop = (e, destPile) => {
    console.log("Drop detected:", destPile);
    e.preventDefault();
    const cardData = e.dataTransfer.getData("application/json");
    const movingCards = JSON.parse(cardData);
  
    const srcPile = findCardPile(movingCards[0]);
  
    if (!srcPile) {
      console.error("Source pile not found for the dragged cards.");
      return;
    }
  
    const moveJSON = {
      cards: movingCards,
      src: srcPile,
      dst: destPile,
    };
  
    console.log("Sending move request:", moveJSON);
    sendMoveRequest(moveJSON);
  };

  const onClick = (card, pileName, cardIndex) => {
    console.log("Click detected:", { card, pileName, cardIndex });

    if (pileName === "draw") {

        sendMoveRequest({ cards: [], src: "draw", dst: "discard" });
    }
    // No action needed for other piles
};

  const onDragOver = (e, pileName) => {
    e.preventDefault();
    // Optionally, you can add visual feedback here
  };

  const findCardPile = (cardToFind) => {
    // Search through all piles to find the source pile of the dragged card
    const piles = [
      "pile1", "pile2", "pile3", "pile4", "pile5", "pile6", "pile7",
      "stack1", "stack2", "stack3", "stack4",
      "draw", "discard"
    ];

    for (const pileName of piles) {
      const pile = state[pileName];
      for (let i = 0; i < pile.length; i++) {
        const card = pile[i];
        if (
          card.suit === cardToFind.suit &&
          card.value === cardToFind.value &&
          card.up === cardToFind.up
        ) {
          return pileName;
        }
      }
    }
    return null;
  };

  return (
    <GameBase>
      {/* "View Game Results" Button */}
      <ButtonContainer>
        <StyledButton to={`/results/${id}`}>View Game Results</StyledButton>
      </ButtonContainer>

      <CardRow>
      <Pile
        pileName="stack1"
        cards={state.stack1}
        spacing={0}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
      />
      <Pile
        pileName="stack2"
        cards={state.stack2}
        spacing={0}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
      />
      <Pile
        pileName="stack3"
        cards={state.stack3}
        spacing={0}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
      />
      <Pile
        pileName="stack4"
        cards={state.stack4}
        spacing={0}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
      />
        <CardRowGap />
        <Pile
          pileName="draw"
          cards={state.draw}
          spacing={0}
          onClick={onClick}
        />
        <Pile pileName="discard" cards={state.discard} spacing={0} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
      </CardRow>
      <CardRow>
        <Pile pileName="pile1" cards={state.pile1} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
        <Pile pileName="pile2" cards={state.pile2} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
        <Pile pileName="pile3" cards={state.pile3} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
        <Pile pileName="pile4" cards={state.pile4} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
        <Pile pileName="pile5" cards={state.pile5} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
        <Pile pileName="pile6" cards={state.pile6} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
        <Pile pileName="pile7" cards={state.pile7} onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} />
      </CardRow>
      <ExtraSpace />
    </GameBase>
  );
};