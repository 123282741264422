import React from "react";
import styled, { keyframes } from "styled-components";

// Fade-in and fade-out animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

// Styled notification card
const NotificationCard = styled.div`
  position: fixed;
  top: 10%;
  right: 10%;
  background-color: #4caf50; /* Green for success */
  color: white;
  padding: 1em 2em;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${(props) => (props.visible ? fadeIn : fadeOut)} 0.5s ease-out;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: none;
  z-index: 1000;
  transition: opacity 0.5s, transform 0.5s;
`;

export const Notification = ({ visible, message }) => {
  return (
    <NotificationCard visible={visible}>
      {message || "Profile Updated Successfully!"}
    </NotificationCard>
  );
};