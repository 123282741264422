//components/pile.js

import PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";

const CardImg = styled.img.attrs((props) => ({
  style: {
    left: `${props.left}%`,
    top: `${props.top}%`,
  },
  src: props.src, // Include src here
}))`
  position: absolute;
  height: auto;
  width: 100%;
  ${(props) =>
    props.isHighlighted &&
    `
    border: 2px solid yellow;
    border-radius: 5px;
  `}
`;

export const Card = ({ card, top, left, onDragStart, isHighlighted, index }) => {
  const source = card.up
    ? `/images/${card.value}_of_${card.suit}.png`
    : "/images/face_down.jpg";

  return (
    <CardImg
      src={source}
      alt={`${card.value} of ${card.suit}`}
      left={left}
      top={top}
      isHighlighted={isHighlighted}
      draggable={card.up}
      onDragStart={(e) => onDragStart(e, card, index)}
    />
  );
};


// Add the onClick handler to PileBase
const PileBase = styled.div`
  margin: 5px;
  position: relative;
  display: inline-block;
  border: dashed 2px #808080;
  border-radius: 5px;
  width: 12%;
`;

const PileFrame = styled.div`
  margin-top: 140%;
`;



export const Pile = ({
  pileName,
  cards = [],
  spacing = 8,
  horizontal = false,
  onDragStart,
  onDrop,
  onDragOver,
  onClick,
  highlightedCards,
}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    if (onDragOver) {
      onDragOver(e, pileName);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (onDrop) {
      onDrop(e, pileName);
    }
  };

  const children = cards.map((card, i) => {
    const top = horizontal ? 0 : i * spacing;
    const left = horizontal ? i * spacing : 0;
    const isHighlighted =
      highlightedCards &&
      highlightedCards.pileName === pileName &&
      highlightedCards.indices.includes(i);
      return (
        <Card
          key={i}
          card={card}
          top={top}
          left={left}
          onDragStart={onDragStart}
          isHighlighted={isHighlighted}
          index={i}
        />
      );
    });

  return (
    <PileBase
      onClick={onClick ? () => onClick(null, pileName, 0) : undefined}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      <PileFrame />
      {children}
    </PileBase>
  );
};

Pile.propTypes = {
  pileName: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
  horizontal: PropTypes.bool,
  spacing: PropTypes.number,
  highlightedCards: PropTypes.object,
};
