"use strict";
//src/client/components/register.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormLabel,
  FormInput,
  FormButton,
  ModalNotify,
} from "./shared.js";
import { validPassword, validUsername } from "../../shared/index.js";


export const Register = (props) => {
  let navigate = useNavigate();
  let [state, setState] = useState({
    username: "",
    first_name: "",
    last_name: "",
    city: "",
    primary_email: "",
    password: "",
  });
  let [error, setError] = useState("");
  let [notify, setNotify] = useState("");

  useEffect(() => {
    document.getElementById("username").focus();
  }, []);

  const onChange = (ev) => {
    setError("");
    const { name, value } = ev.target;
    setState({
      ...state,
      [name]: name === "username" ? value.toLowerCase() : value,
    });
    // Validate username
    if (name === "username") {
      let usernameInvalid = validUsername(value);
      if (usernameInvalid) setError(`Error: ${usernameInvalid.error}`);
    }
    // Validate password
    else if (name === "password") {
      let pwdInvalid = validPassword(value);
      if (pwdInvalid) setError(`Error: ${pwdInvalid.error}`);
    }
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    const res = await fetch("/v1/user", {
      body: JSON.stringify(state),
      method: "POST",
      credentials: "include",
      headers: { "content-type": "application/json" },
    });
  
    const data = await res.json();
    if (res.ok) {
      setNotify(`User ${state.username} registered successfully.`);
    } else {
      setError(data.error || "An error occurred");
    }
  };

  const onAcceptRegister = () => {
    navigate("/login");
  };

  return (
    <div style={{ gridArea: "main", padding: "1em" }}>
      {notify !== "" ? (
        <ModalNotify
          id="notification"
          msg={notify}
          onAccept={onAcceptRegister}
        />
      ) : null}
      <ErrorMessage msg={error} />
      <FormBase
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          width: "100%",
        }}
      >
        {/* Grid Container */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gridGap: "0.5em",
            alignItems: "center",
          }}
        >
          <FormLabel htmlFor="username">Username:</FormLabel>
          <FormInput
            id="username"
            name="username"
            placeholder="Username"
            onChange={onChange}
            value={state.username}
            style={{ width: "100%" }}
          />

          <FormLabel htmlFor="first_name">First Name:</FormLabel>
          <FormInput
            id="first_name"
            name="first_name"
            placeholder="First Name"
            onChange={onChange}
            value={state.first_name}
            style={{ width: "100%" }}
          />

          <FormLabel htmlFor="last_name">Last Name:</FormLabel>
          <FormInput
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            onChange={onChange}
            value={state.last_name}
            style={{ width: "100%" }}
          />

          <FormLabel htmlFor="city">City:</FormLabel>
          <FormInput
            id="city"
            name="city"
            placeholder="City"
            onChange={onChange}
            value={state.city}
            style={{ width: "100%" }}
          />

          <FormLabel htmlFor="primary_email">Email:</FormLabel>
          <FormInput
            id="primary_email"
            name="primary_email"
            type="email"
            placeholder="Email Address"
            onChange={onChange}
            value={state.primary_email}
            style={{ width: "100%" }}
          />

          <FormLabel htmlFor="password">Password:</FormLabel>
          <FormInput
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            onChange={onChange}
            value={state.password}
            style={{ width: "100%" }}
          />

          {/* Empty div to align buttons with inputs */}
          <div></div>
          {/* Buttons Container */}
          <div style={{ width: "100%" }}>
            {/* Register Button */}
            <FormButton
              id="submitBtn"
              onClick={onSubmit}
              style={{
                width: "100%",
                marginBottom: "0.5em",
              }}
            >
              Register
            </FormButton>

            {/* Register with GitHub Button */}
            <a
              href="/v1/auth/github"
              style={{
                textDecoration: "none",
                width: "100%",
                display: "block",
              }}
            >
              <FormButton
                type="button"
                style={{
                  backgroundColor: "#333",
                  color: "#fff",
                  width: "100%",
                }}
              >
                Register with GitHub
              </FormButton>
            </a>
          </div>
        </div>
      </FormBase>
    </div>
  );
};

Register.propTypes = {
  logIn: PropTypes.func.isRequired,
};