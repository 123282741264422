/* Copyright G. Hemingway, 2024 */
"use strict";

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Styled components
const LandingBase = styled.div`
  position: relative;
  width: 100vw; /* Ensure it takes the full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
  margin: 0; /* Remove potential margins */
  padding: 0; /* Remove potential padding */
  box-sizing: border-box; /* Include padding and borders in dimensions */
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Cards container
const CardsContainer = styled.div`
  position: absolute;
  width: 120%;
  height: 100%;
`;

// Card component
const Card = styled(motion.img)`
  position: absolute;
  width: 100px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

// Content over the cards
const Content = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
`;

// Title and subtitle
const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

// Start button
const StartButton = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
  background-color: #ff5722;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e64a19;
  }
`;

export const Landing = () => {
  // Array of card image filenames
  const cardNames = [
    "2_of_clubs.png",
    "3_of_diamonds.png",
    "4_of_hearts.png",
    "5_of_spades.png",
    "6_of_clubs.png",
    "7_of_diamonds.png",
    "8_of_hearts.png",
    "9_of_spades.png",
    "10_of_clubs.png",
    "jack_of_diamonds.png",
    "queen_of_hearts.png",
    "king_of_spades.png",
    "ace_of_clubs.png",
  ];

  // Create an array of card components with animations
  const cards = Array.from({ length: 30 }, (_, index) => {
    const delay = Math.random() * 5;
    const xStart = Math.random() * window.innerWidth;
    const yStart = -150;
    const xEnd = xStart + (Math.random() * 200 - 100);
    const yEnd = window.innerHeight + 150;
    const rotation = Math.random() * 360;
    const cardIndex = Math.floor(Math.random() * cardNames.length);
    const cardImage = `/images/${cardNames[cardIndex]}`;

    return (
      <Card
        key={index}
        src={cardImage}
        alt="Playing card"
        style={{
          left: xStart,
          top: yStart,
        }}
        initial={{ opacity: 0 }}
        animate={{ x: xEnd, y: yEnd, rotate: rotation, opacity: 1 }}
        transition={{
          duration: 10,
          delay: delay,
          repeat: Infinity,
          repeatType: "loop",
        }}
      />
    );
  });

  return (
    <LandingBase>
      <CardsContainer>{cards}</CardsContainer>
      <Content>
        <Title>Welcome to Klondike Solitaire</Title>
        <Subtitle>Experience the classic game like never before.</Subtitle>
      </Content>
    </LandingBase>
  );
};